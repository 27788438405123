import React, { useContext } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/useWindowDimensions';
import AssetsContext from './assetsContext';
import Img from 'gatsby-image/withIEPolyfill';
import JoinCampaignWidget from './HeaderComponents/JoinCampaignWidget';
import { generateSections } from './_generators';
import Headline from './BodyComponents/Headline';
import CustomMarkdown from './_common/CustomMarkdown';

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 369px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
    height: 100%;
  }
`;

const HeaderImageContainer = styled.div`
  width: 100%;
  margin-top: 69px;
  height: auto;
  max-height: 300px;
  position: relative;
  min-height: 300px;
  display: inline-block;
`;

const HeaderColorOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(154, 124, 147, 0.35);
  z-index: 50;
  height: 100%;
`;

const HeaderImageGatsby = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: ${(props) => (props.mobileView ? '' : 'right')};
  min-height: 300px;
`;

const HeaderChildrenOuterContainer = styled.div`
  width: 100%;
  margin-top: 69px;
  max-width: ${(p) =>
    p.theme.siteConfig.maxContentWidth
      ? p.theme.siteConfig.maxContentWidth
      : 990}px;
  position: ${(p) => (p.mobileView ? 'relative' : 'absolute')};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
    margin-top: 0px;
  }
`;

const HeaderTitleSectionContainer = styled.div`
  width: 100%;
  position: absolute;
  top: calc(50% - ${(p) => (p.titleSize ? p.titleSize / 2 : 40)}px);
  left: 10px;
  z-index: 100;

  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
    top: -${(p) => (p.mobileTitleSize ? 125 - (80 - p.mobileTitleSize) : 125)}px;
    left: 0px;
  }
`;

const HeaderSubtitle = styled.div`

  p {
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: ${(p) => (p.subtitleSize ? p.subtitleSize : 36)}px;
    line-height: ${(p) => (p.subtitleSize ? p.subtitleSize : 36)}px;
    color: ${(p) => p.theme.colors.white};
    margin-left: 2px;
    font-family: interstate-condensed;

    strong {
      font-family: interstate-condensed-bold;
    }
  }
  /* h2 {
    margin-block-start: 0em;
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
  } */

  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
    left: 0;
    right: 0;
    bottom: unset;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    
    ${p => p.mobileSubtitleSize === -1 ? `
    display: none;
    ` : ``}
    p {
      width: unset;
      margin-block-start: 0em;
      margin-block-end: 0em;
      font-size: ${(p) => (p.mobileSubtitleSize ? p.mobileSubtitleSize : 24)}px;
      line-height: ${(p) => (p.mobileSubtitleSize ? p.mobileSubtitleSize : 24)}px;
    }
  }
`;

const HeaderTitle = styled(Headline).attrs((p) => ({
  h: 1,
  hideTopArrow: true,
}))`

  font-size: ${(p) => (p.titleSize ? p.titleSize : 80)}px;
  line-height: ${(p) => (p.titleSize ? p.titleSize : 80)}px;
  color: ${(p) => p.theme.colors.white};

  h1 {
    margin-block-start: 0em;
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
  }

  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
    left: 0;
    right: 0;

    bottom: unset;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: ${(p) => (p.mobileTitleSize ? p.mobileTitleSize : 80)}px;
    line-height: ${(p) => (p.mobileTitleSize ? p.mobileTitleSize : 80)}px;

    h1 {
      width: unset;
    }
  }
`;

const HeaderTitleSection = ({
  pageTitle,
  titleSize,
  mobileTitleSize,
  pageSubtitle,
  subtitleSize,
  mobileSubtitleSize,
}) => {

  return (
    <HeaderTitleSectionContainer
      hasSubtitle={pageSubtitle && pageSubtitle.length > 0 ? true : false}
      titleSize={titleSize}
      mobileTitleSize={mobileTitleSize}
      subtitleSize={subtitleSize}
      mobileSubtitleSize={mobileSubtitleSize}
    >
      <HeaderTitle titleSize={titleSize} mobileTitleSize={mobileTitleSize}>
        {pageTitle}
      </HeaderTitle>
      <HeaderSubtitle
        subtitleSize={subtitleSize}
        mobileSubtitleSize={mobileSubtitleSize}
      >
        <CustomMarkdown>{pageSubtitle ? (pageSubtitle && pageSubtitle.length > 1 ? pageSubtitle.map(text => `${text}  \n`).join('') : pageSubtitle[0]) : ''}</CustomMarkdown>
      </HeaderSubtitle>
    </HeaderTitleSectionContainer>
  );
};

const HeaderChildrenContainer = styled.div`
  width: ${(p) => (p.width ? p.width : 350)}px;
  height: 100%;
  right: 10px;
  position: absolute;
  z-index: 100;
  top: 0;
  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 5px;
    margin-bottom: 10px;
    right: unset;
  }
  @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.smallMobile}px) {
  }

  > div {
    /* padding-top: 79px; */
    /* padding-bottom: 10px; */
    background-color: ${(props) =>
      props.theme.headerBreakoutSection.background};
    height: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.mobile}px) {
      padding-top: 0px;
      padding-bottom: 0px;
      align-items: unset;
    }
    @media (max-width: ${(p) => p.theme.siteConfig.breakpoints.smallMobile}px) {
    }
  }
`;

const JoinWidget = styled(JoinCampaignWidget)``;

const ShortHeader = ({
  className,
  headerProps,
  signupWidgetProps,
  mobileView,
}) => {
  const files = useContext(AssetsContext).fileUrls;
  // const imagesMaxUrls = useContext(AssetsContext).imagesMaxUrls;
  const imagesMaxByName = useContext(AssetsContext).imagesMaxByName;

  const { height, width } = useWindowDimensions();

  function getContainerDimensions(windowWidth) {
    let height;

    if (windowWidth > 952) {
      height = 610;
    } else if (windowWidth > 783) {
      height = 0.75 * windowWidth;
    } else {
      height = 0.9 * windowWidth;
    }

    return {
      height,
      width: windowWidth,
    };
  }

  return (
    <HeaderContainer className={className}>
      <HeaderImageContainer dimensions={getContainerDimensions(width)}>
        <HeaderImageGatsby
          fluid={imagesMaxByName[headerProps.config.headerImageName]}
          mobileView={mobileView}
          objectPosition={mobileView ? 'center center' : 'right center'}
          imgStyle={{ maxHeight: 300 }}
        />
        <HeaderColorOverlay
          dimensions={getContainerDimensions(height, width)}
        />
      </HeaderImageContainer>
      {signupWidgetProps ? (
        <JoinWidget
          anchorName={'getInvolved'}
          mobileView={mobileView}
          widgetProps={signupWidgetProps}
          logoUrl={files[signupWidgetProps.logoImageName]}
        />
      ) : (
        <HeaderChildrenOuterContainer mobileView={mobileView}>
          <HeaderTitleSection
            subtitleSize={headerProps.config.subtitleSize}
            mobileSubtitleSize={headerProps.config.mobileSubtitleSize}
            mobileTitleSize={headerProps.config.mobileTitleSize}
            titleSize={headerProps.config.titleSize}
            pageTitle={headerProps.pageTitle}
            pageSubtitle={headerProps.pageSubtitle}
          />

          <HeaderChildrenContainer
            width={headerProps.config.childrenWidth}
            mobileView={mobileView}
          >
            <div id={'HeaderChildrenContainerContents'}>
              {/* <BigLogo backgroundColor={'none'} mobileView={mobileView} logoUrl={files[headerProps.logoImageName]} /> */}
              {generateSections(headerProps.childComponents, mobileView)}
            </div>
          </HeaderChildrenContainer>
        </HeaderChildrenOuterContainer>
      )}
    </HeaderContainer>
  );
};

export default ShortHeader;
