import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../layout';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import BodyContainer from '../BodyContainer';
import BodyComponents from '../BodyComponents';
import ShortHeader from '../ShortHeader';
import Helmet from 'react-helmet';

function generateBodySection({ component, params }, bodySectionId, mobileView, unifiedBody) {
  if (BodyComponents[component]) {
    const SectionComponent = BodyComponents[component];
    // console.log(SectionComponent);
    return <SectionComponent {...params} unifiedBody={unifiedBody} mobileView={mobileView} key={component + bodySectionId}/>;
  } else {
    return <div>{component}</div>;
  }
}

const generateBodySections = (bodySections, mobileView, unifiedBody) => {
  // console.log(bodySections)
  return Object.getOwnPropertyNames(bodySections).map((bodySectionId) => {
    // console.log(bodySections[bodySectionId]);
    // console.log(bodySectionId)
    return generateBodySection(bodySections[bodySectionId], bodySectionId, mobileView, unifiedBody);
  });
};

const JoinUsPage = (props) => {
  const [t] = useTranslation(['joinus','translation']);
  const { width } = useWindowDimensions();

  let mobileView = false;

  const bodySections = {
    ...t('bodySections', { returnObjects: true }),
    // ...sharedSettings.bodySections // no shared settings for this page
  };
  const headerProps = t('header', { returnObjects: true });

  if (width < 750 && width > 380) {
    mobileView = 'mid';
  } else if (width <= 380) {
    mobileView = 'small';
  } else {
    mobileView = false;
  }

  return (
    <Layout>
      <Helmet>
        <title>{t('translation:siteTitle')} - {t('pageTitle')}</title>
      </Helmet>
      <ShortHeader
        mobileView={mobileView}
        headerProps={headerProps}
      />
      <BodyContainer>
        {generateBodySections(bodySections, mobileView, t('unifiedBody'))}
      </BodyContainer>
    </Layout>
  );
};

export default JoinUsPage;
